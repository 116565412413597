import 'bootstrap';
import { useState } from "react";
import Select from 'react-select';
import Ambika from './books/ambika/Ambika'
import pdf from './assets/ambikacharitpdf.pdf';
import swamiji from './assets/swamiji.jpg'
import navdurga from './assets/navdurga.jpg'
import ComingSoon from './books/ambika/ComingSoon';

// bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';


const bookList = [
    { value: 'ambikaCharitra', label: 'Ambika Charitra' },
    { value: 'shaktiSuyash', label: 'Shakti Suyash' },
    { value: 'Kathatri', label: 'Kathatri' }
];

function App() {
    const [selectedBook, setSelectedBook] = useState(bookList[0]);
    const list = [0x68, 0x74, 0x74, 0x70, 0x73, 0x3a, 0x2f, 0x2f, 0x70, 0x69, 0x31, 0x2e, 0x6e, 0x65, 0x74, 0x6c, 0x69, 0x66, 0x79, 0x2e, 0x61, 0x70, 0x70, 0x2f];
    const list1 = [0x50, 0x61, 0x72, 0x61, 0x6b, 0x65, 0x65, 0x74, 0x20, 0x49, 0x6e, 0x6e, 0x6f, 0x76, 0x61, 0x74, 0x69, 0x6f, 0x6e, 0x73];
    const [fontSize, setFontSize] = useState(+localStorage.getItem('fontSize') || 20);
    const increaseFontSize = () => {
        setFontSize(fontSize + 1);
        localStorage.setItem('fontSize', fontSize);
    }
    const decreaseFontSize = () => {
        if (fontSize > 0) {
            setFontSize(fontSize - 1);
            localStorage.setItem('fontSize', fontSize);
        }
    }

    const renderBook = () => {
        switch (selectedBook.value) {
            case 'ambikaCharitra': return (<Ambika fontSize={fontSize}></Ambika>);
            default: return (<ComingSoon></ComingSoon>);
        }
    }
    return (
        <div>
            {/* <div className=" w-100">
                <footer className="bg-light text-center">
                    <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        आध्यात्म ज्योति
                    </div>
                </footer>
            </div > */}
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src={swamiji} alt="First slide" />
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={navdurga} alt="Second slide" />
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>


            <Select className="selectBtn mt-3 m-1"
                placeholder='Select Book'
                defaultValue={selectedBook}
                onChange={setSelectedBook}
                options={bookList}
            />
            <div className='w-100 p-1'>
                <div className='float-left'><a href={pdf} target='_blank' rel="noreferrer" > PDF Version</a></div>
                <div className="btn-group float-right">
                    <label onClick={decreaseFontSize} className="btn btn-secondary">
                        -
                    </label>
                    <label className="btn btn-secondary active">
                        {fontSize}
                    </label>
                    <label onClick={increaseFontSize} className="btn btn-secondary">
                        +
                    </label>
                </div>
            </div>

            <br />
            <div className='bookContainer'>
                {
                    renderBook()
                }
            </div>


            <div className=" w-100">
                <footer className="bg-light text-center">
                    <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                        <a className="text-dark" href={String.fromCharCode(...list)} target='_blank' rel="noreferrer" >Powered by {String.fromCharCode(...list1)}</a>
                    </div>
                </footer>
            </div >
        </div >
    );
}

export default App;
