
import './ambika.css';
function Ambika({ fontSize = 0 }) {
    return (
        <div className="WordSection1">
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize + 8}.0pt`, fontFamily: '"Kruti Dev 010"', fontWeight: 'bold' }}>vfEcdk pfj=</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>AAJh x.kifr LrqfrAA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx % ';ke dY;k.k </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ueks ueks fgefxjh dU;k dqekjAAVsjAA &nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>xt eq[k oark] v: bd nark] ewld gS
                vlokjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>eaxy eksn vkuan c/kkou] fj)h flf)
                nkrkjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tl ojuwa txnEc vEcdks] ck/kk fo?ku
                fuokjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>AAJh ljLorh LrqfrAA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ % vEckth jh eSanh ek.kd jaxA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fu'k fnu lqfe:a 'kkjn ekrA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>M.M de.My LQkfVd ekyk] oh.kk iqLrd
                gkFkAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ok.kh cqf) foey dj nhts] nkl jke cfytkrAA
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>AAJh xq: izkFkZukAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vukFk gw¡ xq:ukFk esjh csx lq/k yhft,]
                csx lq/k yhft,] lukFk eksgs dhft,] vukFk gw¡AAVsjAA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rsjs in dey esa Hkzej cu eu esjk] pj.k
                lq/kk iku djsa ;s gh oj nhft,] vukFk gw¡AA1AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>AAansoh izkFkZukAA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ % cSBh tiqa lhrkjke rqylh dh ekyk
                ys nksA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>eks; nhts ojnku nsoh 'kj.k rsjh
                vk;ksjhAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gsjh ojuwa] gsjh ojuwa lqtl eka Fkkjksjh]
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dj ok.kh jks fodkl nsoh 'kj.k rks jh
                vk;ksjh] 'kj.k rsjh vk;ksjhAA1AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>pj.k dey dks] pj.k dey dks ^jke*
                cfygkjh th] vks rks dksfV dksfV ckj nsoh 'kj.k </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rksjh vk;ksjhAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><u><span lang="EN-IN" style={{ fontSize: `${fontSize + 4}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dFkk vkjEHk</span></u></b></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jktk os'kEik;uth Jh osnO;klth ls iz'u
                djrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ % vc dfj;s fd ls I;kj ;kj [kqn xjt
                tekukA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>eks; nhts dFkk lquk; u`ifr ;wa opu
                mpkjs gS] u`ifr ;wa opu mpkjs gSAAVsjA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqurs gS izkphu le; esa nSR; cM+s
                cyoku] tx fo[;kr Hk;s nksÅ HkkbZ] 'kqEHk fu'kqEHk gS uke] Hk;s lc nso nq[kkjs
                gSAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fde dj ;s ikrky yksd ls e`R;q yksd jgs
                vk;] fdedj fQj riL;k fduh xq: rhjFk ij tk; nh;s oj tx djrkjs gSAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>oj ikdj czãk ls nksuksa nso yksd dks
                tk;] idM+ cka/k dj lc nsou dks dSls bUæ gjk;] fn;ks xn~nh ls mrkjs gSAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fde dj vLrqfr nsou us djds djh iqdkj]
                fdedj fQj ijlUu gks; dj p.Mh ys vorkj nh;s lc nSR; foMkjs gSAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; Jh O;klth jktk oS'Eik;u ls
                bl izdkj dFkk o.kZu djrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ukFk fcu fcxM+h dkSu cuk;sA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkt )f"k jktk ls Qjekosa v: ;wa
                dFkk lqukosAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ikrky yksd tks i`Foh ry esa] tUe fy;ks
                ogka nkuo ny esa] 'kqHk fu'kqHk dgkos] v: ;wa dFkk lqukosAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ckyd Fks tc nksuksa HkkbZ] Hkwe.My ns[ku
                ds rkbZ] e`R;qyksd esa vkos] v: ;wa dFkk lqukosAA2AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dky O;rhr gqoks ftfe tkbZ] ckyk chrh
                r:.kk vkbZ]] ri djus dks pkos] v: ;wa dFkk lqukosAA3AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>/kkj ;gh fu'p; eu ekgha] iq"dj
                jkt x;s nksm HkkbZ] /;ku lekf/k yxkos] v: ;wa dFkk lqukosAA4AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>djrs ;waa riL;k Hkkjh] cjl chr x;s nl
                gtkjh] czãk izlUu gks; tkos] v: ;wa dFkk lqukosAA5AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj fu'kqaHk lekf/k
                [kksy czãkth dks fudV ns[k dj oj ;kpuk djrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; xwq:ukFk gS izk.k gekjk
                fof/k ,slk opu mpkjkA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dj tksj vjt xqnjkosa ojnku ;gh ge
                ikosaAAVsjAA &nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lalkj pØ ds ekagh] gsjh lalkj pØ ds
                ekagh] dksbZ dky cyh le ukgha th] D;k jko jad lc tkos] ojnku ;gh ge ikosAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;g vHk;nku cxlhts] gsjh ;s vHk;nku
                cxlhts] v: euokafNr oj nhtsth] ge vtj vej gks; tkos] ojnku ;gh ge ikosAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; czãkth 'kqHk fu'kqaaHk dks
                bl izdkj mÙkj nsrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; pkSikbZ</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; tS] tS] tS] fxjhjkt
                fd'kksjhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqur opu fof/k eu eqldkbZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>v: ;wa opu dgr le&gt;kbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;k lalkj pØ ds ekabZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dky leku cyh dksbZ ukabZa thAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>D;k jktk D;k rqPN fHk[kkjh] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tho tUrq lc gh nsg/kkjhAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tks dksbZ tues gS lksbZ tkbZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dky cfy lc gh dks [kkosAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;g vuqfpr opu rt nsoks] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>v: dNq vkSj ekax ys ysoksAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkt ikV /ku /kke lqgkbZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ekax ysoks lc dNq eu pkbZAA6AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; 'kqaHk fu'kqaHk iqu%
                czãkth ls dgrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ujflag :i gfj /kjsA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dNq gsj Qsj prqjkbZ] v: cksys opu
                cukbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>i'kq i{'{'}kh iq:"k dgkos] ugha ekj u
                gesa ldkos] ;g oj ekaxs fljukbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fQj L=h ls tx ekabZ] dNq gedks Hk; Hkh
                ukabZ] og vcyk uke dgkbZAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;fn nsuk gh tks fopkjk] vc nsnks ;gh
                djrkjk] ge lQy gksos oj ikbZA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; 'kqaHk fu'kqaHk ds opu lqu
                czãkth izlUu gks ^rFkkLrq* ,slk dg oj ns vius LFkku ij pys tkrs gSaA rRi'pkr~
                os nkuo Hkh vius ?kj ikrky yksd esa Hk`xq eqfu dks viuk iqjksfgr cuk dj mldk
                iwtu djrs gSaA Hk`xqth 'kqHk fnu ns[k Lo.kZ dk flagklu cuok T;s"B Hkzkrk
                'kqqaHk dks ml ij fcBkdj mldk jkT;kfHk"ksd djrs gSA bl izdkj 'kqHk ds jktk
                gks tkus ds lekpkj lqudj nSR;x.k mudh lsok djus ds fufer vkrss gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; ekaM</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; f=iqjk; ekath vkijks ekuks
                ?kuks NS fo'oklA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqu 'kqEHk dks jkT;fHk"ksd gqvks
                lc vk;s nSR; lektAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>p.M v: eq.M cM+s izjp.M tks ;ks)k cM+s
                cyoku] ysdj ds fut QkStu o`an ;wa vk;s dju lEekuAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>/kwejykspu uke egkcyh] dky leku djky]]
                jDrcht jk{'{'}kl j.kcadk] vk;s ikl u`iky AA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>NksVs vkSj cM+s dbZ nkuo lsuk lfgr
                vikj] gfLr] ?kksM+k] jFk v: iSny] 'kqEHk lksgs njckjAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj vla[; lsuk dk ifr
                'kqHk lc i`Foh dks thr LoxZ yksd dks tk vkSj bUækfn nsoks dks gjk] dYi o`{'{'}k]
                dke/ksuq lfgr bUæklu xzg.k djrk gSA nsork dafir xkr gks uUnu ou dks NksM+
                ioZrksa dh danjksa esa Hkkx tkrs gSaA bl izdkj 'kqaHk dks jkT; djrs bDdhl gtkj
                o"kZ chr tkrs gSa vkSj nsorkx.k vfr nq[kkrkj gks vius xq: o`gLifrth ds ikl
                tkrs gSa vkSj dgrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; xty </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; jke n'kjFk dks ?kj tUes]
                ?kjkuk gks rks ,slk gksA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>bUæ v: nso lc tkbZ] dgs xq: ls ;wa
                f'kjukbZAAVsjA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>osn v: 'kkL= ds Kkrk] loZ xq.koku gks rkrk]
                crk rnchj dNq nhts foMkjs nSR; nq[knkbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; xq: o`gLifrth nsorkvksa ls
                bl izdkj vHk;nku ns nSR;ksa ds uk'k dk mik; crkrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; xty </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; mBks j?kqukFk tu I;kjs] lHkh
                /kuq ns[k fg; gkjsA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dgs xq: jkt le&gt;kbZ] /kjks dNq /khj
                euekabZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>txr tuuh egkek;k] lnk laru dh
                lq[knk;k]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ijs tc Hkhj HkDru esa djs &gt;V vk; ds
                lgkbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fgeky; uke fxjh Hkkjh] /kke je.khd eu gkjh]
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>pj.k jt ogka xgks tkbZ] tgka p.Mh
                egkekbZAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>/kkj dj ds tks vorkjk] ftUgksaus
                egh"k dks ekjk] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>xgks vc mlgh dk lgkjk] vU; rnchj dksm
                ukabZaAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj xq: ds opu lqu
                mudh vkKkuqlkj lc nsox.k fgeky; igkM+ ij tk egkek;k Jh Hkxorh ds /;ku esa
                ijk;.k dks ân; ls Jh ek;kcht dk ti djrs gSa vkSj nsohth dk L=ksr ea=ksa ls
                Lrqfr djrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; cgkj esjs I;kjs Qwyu dh
                ysyks cgkjA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lgk; txnEcs dhtks gekjh lgk;] vk;s gS
                ge rks 'kj.k esa frgkjh] dhtks gekjh lgk;AAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>efgek frgkjh vxE; vikjh] xkos gS osn
                iqjk.kk] iqjk.k txnEcs dhtks gekjh lgk;AA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fo'o pjkpj dks dj dj ds ikyu] nsrh gks
                var [kik;] [kik; txnEcs dhtks gekjh lgk;AA2AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>czãk vks fo".kq egs'k jVr gS]
                ikos ugha dksbZ ikj] ikj txnEcs dhtks gekjh lgk;AA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gerks vKkuh dNq ugha tkus] HkfDr dks
                uke fu'kku] fu'kku txnEcs dhtks gekjh lgk;AA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>'kqEHkkfn nSR; cM+s vfHkekuh] nsrs gSa
                nq[k vikj] vikj txnEcs dhtks gekjh lgk;AA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vkdj eka 'kh?kz gh budks lagkjks]
                nsoks Hkw Hkkj mrkj] mrkj txnEcs dhtks gekjh lgk;AA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj nsorkvksa dh
                Lrqfr lqudj nsohth izdV gksrh gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; VksMh </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; Hkksj Hk;s tc pyks vafcdk
                iwtu jkt nqykjhA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gks; izlUu Hkokuh eS;k] izdV :i
                nj'kk;ks gsjh] gks; izlUuAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Tokyk tkx mBh bd ru ls] fnO; :i
                izxVk;ks gsjhAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>d`".k oj.k fodjky 'kjhjk] dkyh
                uke dgk;ks gsjhAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>,d Hk;dkjh v: bd lqUnj] eugj :i
                /kjk;ks gsjhAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>nj'ku dj gj"ks lc nsok] euokafNr oj
                ik;ks gsjhAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj Jh noshth izlUu
                gks nsorkvksa dks vHk;nku ns 'kqaHk fu'kqHk dks ekjus ds gsrq flag ij p&lt;+
                mRd`V :i cuk dkfydk dks cxy esa nck 'kqaHk ds iqj gks tkrh gS vkSj ogka tkdj&nbsp;
                iq"iokfVdk esa fLFkr gks lalkj dks eksgus okyk lw{'{'}e jkx xkrh gSA brus esa
                'kqaHk ds lsod paM v: eqaM [ksyrs gq, vdLekr ogka vk tkrs gS vkSj fnO; :i Jh
                vfEcdk ds Lo:i dks ns[k foLe; ;qDr gks rqjUr 'kqaHk ds ikl tkrs gS vkSj dgrs
                gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; Bqejh </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; D;k tks dgw¡ esjs ru eu dh
                Tokyk tkur gks lc ?kV ?kV jhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>p.M v: eq.M x;s nksÅ HkkbZ] fut Lokeh
                ls [kcj djkbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>iq"i okfVdk esa bd ;qorh] vkbZ gS
                fl.kxkj ltkbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>:i vuwi ygs ugha miek] eq[k 'kksHkk
                yf[k pUæ ytkbZAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>flag p&lt;+u tkds vlokjh] v: bd Mkfdu cxy
                nckbZAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>e/kqj e/kqj Loj ls eugkjh] xkor jkx gS
                eu gjlkbZAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>uk ns[kh uk dku lquh ge] ,slh lqUnj
                vkSj yqxkbZAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fu'p; dj ;g rqejs ;ksxq] fut iRuh ys
                ysoks cukbZAA+6AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>uk tkus ;g fdldh tkbZ] uk tkus ;gka fdÅa
                dj vkbZAA7AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj paM v: eqaM ds
                ehBs opu lqu izlUu gks 'kqaHk vius ikl gh [kM+s gq, lqxzho uke ds nwr dks gqDe
                nsdj ml L=h dks lke] nke] mik; djds le&gt;k dj fy;k ykus ds fy, Hkstrk gSA
                lqxzho 'kqaHk dk gqDe ikdj tkrk gS vkSj Jh vafcdkth ls bl izdkj dgrk gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; ek: </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; lko.k dh cMh rht lgsY;ka lc
                fey uko.k pkyhthA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vknj lfgr lqxzho gh cksY;ks v: ;wa opu
                lquk;ks th] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jktk 'kqEHk lans'kks HksT;ks] ysdj eSa
                gw¡ vk;ksthAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>D;k Hkwry ikrky LoxZ D;k] rhu yksd ds ekabZ
                th] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>'kqEHk leku cyh u`i nwtks] fu'p; dj
                dksbZ ukabZ thAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>yksdiky fi=knh nsork] lfgr gh bUæ
                gjk;ks th] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>LoxZ yksd dks thr fy;ks gS] v: lc
                oSHko ik;ksthAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqur jko jks jax Lo:ik] jktk eu
                gj"kkbZ th] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fNu 2 fNu 2 iy iy :i ljkos] v: cgq djr
                c&lt;+kbZ thAA3AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkt ikV /ku /kke Hkksx lc] v: ekStk eu
                ekuh th]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jktk 'kqEHk nkl cu jglh] vki gksÅ
                iVjkuh thAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>eSa gwa nwr esjks ;gh dkek] ;krs Øks/k
                er dhtsth</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lksp fopkj 'kh?kz eu ekagh] mÙkj nsnhtsthAA5AA
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh&amp; Jh vafcdkth lqxzho dks mÙkj
                nsrh gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; dkuks eq[k ls u cksys
                cktwcUn [kksysA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>cksyh eu eqldkbZ] ;wa egkekbZAAVsjA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tkur gS ge fu'p; djds 'kqEHk cM+ks
                cyokuk] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>buds le nwtks dksbZ ukabZ] rhu yksd
                esa gS budh cM+kbZ] ;wa egkekbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rhu yksd dks thr ftUgksaus gS ,slk
                oSHko ik;k] idM+ cka/k dj lc nsou dks] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vkSj fn;ks gS ftu bUæ gjkbZ] ;wa
                egkekbZ AA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>:i jax lqUnjrkbZ esa] gS dksbZ nwtks
                ukagh] ;g lc tku: fu'p; djds]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>C;kg dju dks ge ;gka vkbZ] ;wa
                le&gt;kbZAA3AA&nbsp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ij bd iz.k geus gS Bkuk ckyius ls eu
                ekabZ] ;k gh dks ge ifr djasaxh]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ns[k cyk cy djds yM+kbZ] ;wa egkekabZ
                AA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dj laxzke thr dj gedks] vkSj C;ko
                ysoks vkbZ] ;sgh mÙkj ys; gekjk]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tk; nsoksjs rqe 'kqEHk lqukbZ] ;wa
                egkekbZAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj Hkxorh egkek;k ds
                uhfr;qDr vkSj cy lfgr gsrq ;qDr fBBkbZ djds la;qDr opu lqu lqxzho ykSV dj
                'kqaHk ds ikl tkrk gS vkSj 'kqaHk ;s lekpkj lqu vius NksVs HkkbZ fu'kqaHk dks
                cqyk lykg djrk gS vkSj rnvkUrj /kwezykspu uke ds nSR; dks cqykdj gqDr nsrk gS
                fd &amp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ukps xkos ukjh I;kjh pyh
                tkvks cfygkjh frgkjhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tkoksjs lsuk ys tkoks Mjkoks /kedkvks
                le&gt;k ys vkoksAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ekj ihV ok dks dNq uk] vkSj dksbZ gks
                lgkbZ] ekjks vkSj Hkxkoks] Mjkoks /kedkvks le&gt;k ys vkoksAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tks fodjky dkfydk djky] &nbsp;NksM+ks ukabZ
                oka dks HkkbZ] eq.Mu dkV fxjkoks] Mjkoks /kedkvks] le&gt;k ys vkoksAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj 'kqaHk {'}'}kjk
                le&gt;k;k gqvk /kwezykspu lkB gtkj nSR;ksa ds lkFk laxzke djus ds gsrq miou esa
                tkrk gS vkSj Hkxorh Jh egkek;k dks le&gt;kus dk iz;Ru djrk gSA rc Jh dkfydkth
                Øks/k djrh gS vkSj /kwezykspu dks QVdkj dj dgrh gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; ihyw</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ&nbsp; &amp; gks izHkq I;kjs uUn nqykjs]
                djgks d`ik rqe eqjyh okysA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>/kwezpksyu dks le&gt;kos] dkyh Øks/k
                dj opu lqukosAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>f/kd ewj[k gs cqf) frgkjh] xfr efr ekr
                dh tku u ikosAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rhu yksd dks ikyu gkjh] f'ko v)kZafxuh
                uke dgkosAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;g fdÅs djds 'kqEHk fookgs] flaguh D;k
                dksbZ L;kj ljkosAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gfLruh dkekrqj gks rks Hkh] xjnHk ls
                ukagh nsg yxkosAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>'kqEHk ls tk; dgks le&gt;k dj] ;q) djs
                ;k ikrky gh tkosAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj dkyh fd opu lqu
                /kwezykspu Øks/k dj dkyh ds lkFk laxzke djrk gSA ck.k] [kM~x] xnk] 'kfä vkSj
                ewlykfn ls nsoh nkuoksa ds chp Hk;adj ;q) gksrk gS vkSj Jh dkfydkth ,d ,d djds
                nSR;ksa dks ekj Mkyrh gS fQj vfr Øks/k djds ofkl pykrs gq, /kwezykspu dks Hkh
                ^gq¡dkj* 'kCn ls HkLe dj Mkyrh gSA mldks ifrr tku cph gqbZ lsuk Hkkx dj 'kqaHk
                ds ikl tkrh gS vkSj dgrh gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; dkQh </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; dksbZ fnu ;kn djksxs jerk
                jke QdhjA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;q) dks gky dgs D;k] lqfu;s 'kqaHk
                egkjktAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dkyh lax laxzke gqvks lc] gkjs nSR;
                lektAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lwjohj j.k [ksr jgs lc] ge vk;s gSa
                HkktAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>/kwej ykspu HkLe gqvks lc] ^gw¡* dkyh
                dh vkoktAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; /kwezykspu ds HkLe gq,
                tkus ds lekpkj lqu dj 'kqaHk vius NksVs HkkbZ fu'kqaHk dks cqykdj lykg djrk gSA
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&amp;foJke&amp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; rksjh Ny cy gS U;kjhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqu 'kqaaHk ;g ckr cksy fy;ks gS rkr
                v: lYyk djs gS fopkj pkj pkjAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>/kwej ykspu vks lc ;ks)k] dkyh us fn;s
                lHkh gS [kik;] vc yM+s dh uka; jgs pqi yxk;] vkoks djs ;gh fuj/kkj /kkj
                /kkjAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqu 'kqaHk dh ckr cksY;ks fu'kqaHk
                Hkzkr d;ks opu Hkj;ks vgadkj dkj dkjAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>'kwjohj dks /keZ ;gh gS yM+s fHkM+s vks
                ejs j.k ek;a] ge tkds vks HkkbZ] djds tks yM+kbZ idM+ yk;saxs ;gka og ukj ukj
                ukjAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; fu'kqaHk ds opu lqu 'kqaHk
                fopkj djrk gS vkSj izFke paM eqaM dks gh Hkstus dk fu'p; dj muudks cqyk vkSj
                lsuk ys ds laxzke esa tkus dk gqDe nsrk gSA paM eqaM ogka tk nsohth dks fLFkr
                ns[k lkeiwoZd opu cksyrs gSa vkSj dgrs gS &amp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ujflag :i gfj /kkjs fgjuk
                dq'k nkuo ekjsA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dj paM eqaaM prqjkbZ] v: cksys opu
                cukbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vks 'kqaHk cM+ks cyokuk] D;k rqeus
                bUgsa u fiNkuk]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ftu rhu yksd dks gjkbZ] v: lfpifr
                fn;ks HkxkbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dgka 'kqaHk 'kjhj dBksjk] dgka dksey
                vax ;g rksjk] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>D;k L;kj flag ls tkbZ] djus dks dgr
                yM+kbZAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ge tkur gS ;g vktk] djus dks gh fut
                dktk]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka¡ lqjxu rks; cgdkgZ] v: ejus gsrq
                iBkbZAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rc v"Vk·;q( tks gkFkk] v: dkyh
                flag tks lkFkk]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ufga ,d dke dNq vkbZ] tc 'kqaaHk
                djsxks p&lt;+kbZAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka lqjxu vks laxzkek] D;k buls rqedks
                dkek]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lc jkt ikV vf/kdkbZ] yks 'kqaHk dks
                ifr cukbZAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; pafMdk th paM eqaM dks
                mÙkj nsrh gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; Hktjs eu esjs ckj&amp;ckj
                txnEck ekr Hkokuh gSA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqu paM v: eqaM ds cSu Øks/k dj cksfy
                vafcdk ekabZ gSAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>D;ksa cd cd cd cdokn djks] ;w¡ ukgd
                xky ctkbZ gS]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tks ;ks)k gks rks ;q) djks] ugha
                fojFkk le; xaaokbZ gSAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>D;k 'kaqHk fu'kqaaHk dk uke ysr] v:
                nsr gesa /kedkbZ gS]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>,sls nkuo geus igys] vusd fn;s [kikbZ
                gSAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rqe tku ysoks fu'p; djds] ge nsr gS
                lQk lqukbZ gS] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka 'kqaHk fu'kqaHk foMkju dks] ge dky
                :i cu vkbZ gSAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ge ,d ,d djds ekjasxh] lc nSR;u ds
                leqnkbZ gS]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;g tku fopkj Hkyks viuks] Hkxtkoks blh
                esa HkykbZ gSAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj nsoh nkuoksa ds
                chp Hk;adj ;q) gksrk gSA paM eqaM ck.kksa dh o`f"V djrs gSa ftlls vkdk'k
                vkPNkfnr gks tkrk gSA Hkxorh Jh vafcdkth Øks/k djrh gS vkSj muds yykV ns'k ls
                O;k?kz peZ dk oL= cuks] vfr Øwj eqaMks dh ekyk ifgus] vfr ?kksj :i] [kM~x] ikl
                dks /kkj.k fd;s] vfr jksæ :i dkfydk izxV gksrh gS tks ekuks nwljh dkyjkf= dh
                rjg eq[k Qsyk;s gq, vlqj lSfudksa dks ekjrh gSA nSR;ksa dks gkFk esaa idM+ dj
                eq[k esa NksM+] /khjs&amp;/khjs ihl] ?kksM+s] Å¡V] gkfFk;ksa ds lfgr lokjksa
                dks idM+ dj eq[k esa Mky] pcqykdj vÍgkl djrh gSA bl izdkj lsuk dks ekjh tkrh
                ns[k paM Øks/k dj lw;Z ln~'k rst lfgr lqn'kZu pØ gh ds Mksy dk pd nsoh ds Åij
                ekjrk gS vkSj ckj&amp;ckj xjtrk gSA dkyh ml pØ dks ,d iSus ck.k ls dkV Mkyrh gS
                vkSj paMh ,d rh{'{'}.k ck.k paM dks ekjrh gS ftlds yxus ls paM ewafNZr gks i`Foh ij
                fxj iM+rk gSA ;g ns[k eqaM paMh ij ck.k o`f"V djrk gSA paMh eqaM dks
                NksM+s gq, ck.kksa dks eaf=r yksg 'kykdk ls dkV Mkyrh gS vkSj mldks v)Z pUækdkj
                ck.k ls ekjrh gS ftlds yxus ls eqaM Hkh i`Foh ij fxj iM++rk gSA brus esa paM
                eqPNkZ ls mB xnk mBkdj dkfydkth dh nkfguh Hkqtk esa ekjrk gS vkSj dkfydkth
                ck.kksa dh Qkalh ls paM dks Qkaldj cka/k ysrh gSA brus esa eqaM eqPNkZ ls mB
                n`&lt;+ 'kfDr ls mBk dj dkfydk dks ekjrk gSA dkfydk mlh izdkj mldks cka/k
                Qkalll ls cka/k ysrh gS vkSj nksuksa dks idM+ dj vafcdkth ds ikl ys tkdj mudh
                vkKkuqlkj mu nksuksa dk [kM~x ls flj dkV Mkyrh gS vkSj vkuan ls :f/kj iku dj
                r`Ir gksrh gSA bl izdkj paM eqaM ds ekjs tkus ij cps cpk;s nSR; Hkkx dj 'kqaHk
                ds ikl vkrs gS vkSj dgrs gSaA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ek/kks cl fd;ksjs] dqctk ij
                iM+tks csj.k chtyhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dkabZ ckr crkoka gks] gky lq.kkok
                dkabZ ;q) jksAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>'kwjohj ;ks)k lxjs gh ;ks)k vkSj p.M
                eq.M HkkbZ gks jktoh] vkSj paM eqaM HkkbZ] dkyh us ,d ,d djds lcdks fn;s gS [kikbZth]
                dgka gejh pykbZ gks] gky lq.kkok dkabZ ;q) jksAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>e`r tuksa dh yk'kksa ls th] j.k Hkwfe
                Hkj vkbZ gks jktoh j.k Hkwfe Hkj vkbZ] :aMeqaM ls rqEc frjr gS] jä :f/kj ds
                ekbZth] lfjrk cg vkbZ gks] gky lqukoka dkabZ ;q) jksAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ekalkgjh if{'{'}k;ksa ds gjf"kr gS
                leqnkbZ] gks jktoh gjf"kr gS leqnkbZ] jäiku djus ds gsrq tksxu cu py vkbZ
                th] ih ih ds vxkbZ gks] gky lqqukoka dkabZ ;q) jksAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>,slh Hk;adj Hkwfe ds th ns[kr Mj mj
                ykbZ gks jktoh] ns[kr Mj mj ykbZ] tho ys; dj ge pfy vk;s] Hkkx rksjs 'kjukkbZ
                th] vc yhts cpkbZ gks] gky lqukoka dkabZ ;q) jksAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dSlks vpjt gS ;g rkrk] lk/kkj.k lh
                yqxkbZ gks jktoh] lk/kkj.k lh yqxkbZ] lgt gh esa lgL=ksa nkuo nyu fn;s [kikbZ
                th] dNq Hksn u ikbZ gks] gky lqukoka dkabZ ;q) jksAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>chrh rks lks chr xbZ th] vc Hkh dj
                prqjkbZ gks jktoh] vc Hkh dj prqjkbZ] lgifjokj Hkxks egkjktk] Hkwry jgks clkbZ
                th] blh gh esa HkykbZ gks] gky lqukoka dkabZ ;q) jksAA6AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj ds opu lqu 'kqaHk
                eu esa vfr Øks/k dj dkydh xfr dks tu laxzke gh dk fu'p; dj vius ;ks)k jächt
                ukeklqj dks cqyk] ml L=h ¼Jh vfEcdkth½ dks le&gt;k cq&gt;kdj fy;k ykus ds fy;s
                Hkstrk gSA jächt viuh lsuk ys] gfLr] ?kksM+s] jFk o iSny ds lkfk jFk ij lokj
                gks ogka tkrk gS tgk¡ Jhnsohth fo|eku Khan mldks vkrs ns[k nsohth 'ka[k ctkrh
                gS vkSj jächt 'ka[k dh vkokt lqu vfr Øksf/kr dj nsohth ls dgrk gS &amp; </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; fd;s [kq'k ;kj cny fyiVkbZ]
                fpÙkeu ls lst fcNkbZA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vlqj &gt;V cksy mB~;ks &gt;watykbZ] tc
                'ka[k /ofu lqu ikbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>'ka[k ctkrh D;k /kedkrh] gks ge dks
                euen ykbZ] D;k rqeus tkuk Hkx tkosaxs] djds dk;jrkbZ] ge /kwezykspu ukabZ] tc
                'ka[kAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>D;k rqe tkur gks ukgh dh jächt esjks
                ukek] esjs le ;ks)k dksbZ ukagh dj ns[kks rqe laxzkek] ge dgr rqEgsa le&gt;kbZ]
                tc 'ka[kAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tks rqe osn iqjk.k bR;kfn "kV~
                'kkL=ksa dh gks Kkrk] rks bd ;q) dju ds ifgys] lqu yhts esjh ckrka] gS lR;
                &gt;wB dNq ukabZ] tc 'ka[kAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jl fl.kxkj jlksa dk jktk] gS ftlds
                vkKkdkjh] czãk fo".kq egs'k nsork] tho tUrq lc nsg /kkjh] fut fut fr; lax
                lqgkbZ] tc 'ka[kAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vc ;g tku eku eu esjh] rt j.k dh ckrka
                lkjh] lc xq.k :i leku frgkjs] 'kqaHk lnk ;ks)k Hkkjh] rqe Hktks bls fpÙkykbZ]
                tc 'ka[kAA5AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; jächt ds bl izdkj opu lqu
                Jh vafcdkth g¡ldj dgrh gS &amp; </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; l[kh Qqyu esa jktu &gt;wysjh
                &gt;wyukA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>g¡l cksyh Hkokuh ;w¡ jächt lsAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>esjs lax ;q) djtks] gk¡] gk¡] gk¡] gk¡]
                gk¡] nsoks eksdw gjk;] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>eSa o:axh rkgh ls] g¡l cksyhAA1AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ys;dj ;gh lwpuk gk¡] gk¡] gk¡] gk¡]
                gk¡] tkoks nsoks lquk;] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lc gky 'kaqHk ls] g¡l cksyhAA2AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;q) djs pkgs uk gk¡] gk¡] gk¡] gk¡]
                gk¡] tkos tkos ikrky]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Hkkx bUæ yksd ls] g¡l cksyhAA3AA&nbsp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp;&amp; bl izdkj nsoh ds opu
                lqu jächt vkdf"kZr gks vafcdkth ij ck.kksa dh o`f"V djrk gSA vfEcdkth
                vius iSus rhjksa ls mlds vkrs gq, ck.kksa dks dkV nsrh gS vkSj vU; rhjksa ls
                jächt dks ekjrh gS] ftuds yxus ls og eqfPZNr gks jFk ij fxj iM+rk gSA ;g ns[k
                nSR; lsuk esa gkgkdkj ep tkrk gS vkSj lSfud ^gk; ekjs x;s* dg dj jksrs gS]
                ftldks lqu 'kqEHk dkackt laKd vkSj dkyds; laaKd uke ds nkuoksa dks vU; lsuk
                nsdj Hkstrk gS vkSj bl izdkj 'kqEHk ls izsfjr nkuo] nsoh ds lkFk ;q) djrs gSaA
                ;q) ds gksrs gh czãkfn nsorkvksa dh Hksth gq;h 'kfä;ka Hkh ;q) djus ds fy,
                laxzke esa vkrh gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize + 8}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; fi;k feyu ds dke vkt tksxu
                cu vkÅaxhA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fut fut :i lekt 'kfä;ka] lqju iBkbZ
                gSAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>czãk.kh galk vlokjh] lw= de.My dj esa
                /kkjh]] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>daB chp :æk{'{'}k eky /kkj.k dj vkbZ
                gSAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>x:M+klu ihrkEcj /kkjs] 'ka[k pØ v: xnk
                izgkjs] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>foey dey dj eka; oS".koh uke
                dgkbZ gSAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ekgs'ojh o`"kHkk vk:&lt;+k] gkFk
                fy;s [kIij frj'kqyk]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dadu dkjh ukx eky eq.Mu yVdkbZ gSAa3AA
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>,d ,d djds lc gh vkbZ] nSO;k fut fut
                :i cukbZ]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>p&lt;+ okgu fut ys; vL= v: 'kL= ltkbZ
                gSAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj bu 'kfä;kasa dks
                vkbZ gqbZ ns[kdj nsohth vkuUn dks izkIr gksrh gS vkSj nSR;x.k Mjus yxrs gSaA
                blh le; yksd dY;k.k djus okys Jh egknsoth laxzzke esa vkrs gSavkSj Jh vfEcdk th
                ls 'kqaHk fu'kqaHk rfkk vU; nkuoksa dks ekj 'kh?kz gh leLr txr dks fuHkZ; djus
                dk vkns'k nsrs gSaA Jh egknsoth ds bl izdkj opu lqurs gh paMh ds 'kjhj ls ,d
                vfr Hk;kud] vfr izpaaM lkS Jxkfy;ks ds leku ukn djrh gqbZ ,d vn~Hkqr 'kfä
                fudyrh gS tks ^f'konwrh* ds uke ls iz[;kr gSA ;g 'kfä Jh izkIr egknsoth us
                'kqaHk ds ikl tkus rFkk mldks le&gt;kus dk vkxzg djrh gSA rnuqlkj Jh egknsoth
                'kqaHk ds ikl tkrs gS vkSj dgrs gS &amp; </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ft;krj ls cnfj;k cjls]
                l[khjh fnu dSls dVsaxs cgkj dsA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ge vk;s gSa dgk¡ ds iBk;s lksbZ] vc dgr
                rqEgsa le&gt;k; ds AAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rhu yksd dks gw¡ lgakjh] uke esjks
                f=iqjkjh] f'ko nwrh dk yk;k gw¡ eSa laoknk] lqu ysoks ;g fpÙk yxk; ds] gks ge
                vk;sAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>LoxZ yksd dk oSHko lkjk lqjx.k dk
                vf/kdkjk] rt Hkx tkvks blh esa HkykbZ] ugha ejks ;wa xky ctk; ds] gks ge
                vk;sAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj 'kqaHk dks
                le&gt;k dj] Jh egknsoth ykSV tkrs gSA 'kqahk Jh egknsoth ds opu lqu vfr Øks/k
                dj iqu% cgqr ls nkuoksa dks laxzke esa Hkstrk gS] tks Jh nsoh ds lkFk Hk;adj
                ;q) djrs gSA czãk.kh de.My ds ty ls nSR;ksa dks izk.k jfgr djrh gS] ekgs'ojh
                f='kqy ls nkuoksa dks ekj&amp;ekj dj Hkwfe ij fxjkrh gS vkSj oS".koh pØ
                xnk ds ikr ls nkuoksa dks izk.k jfgr djrh gSA bl izdkj lc nsfo;ksa {'}'}kjk lc
                nkuoksa dh lsuk dk uk'k gks tkrk gS vkSj dqN ,d cps cpk;s nkuo jks jks dj
                Hkkxus yxrs gSaA brus esa jächt ewNkZ ls mB Hkkxrs gq, lSfudksa dks jksdrk gS
                vkSj ;q) djus yxrk gSA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; D;k Hkwfy;s fnokus
                nqfu;kaesa lkj ughA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vc jächt mBds] djus yxk yM+kbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gks oS".koh ds tksM+s] dj Øks/k
                ck.k NksM+s]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Nssns frUgs mlhus fut pØ dks
                pykbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;g ns[k vU; nsoh x.k nkSM+ nkSM+ vkbZ]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ys vL=&amp;'kL= vius djrh HkbZ
                p&lt;+kbZAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>br jächt ,sdk] mr nsfo;ka vusdk] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>?kelku ;q) gksos] Mxex /kjk
                /kqtkbZAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tks jächt dk gS] yksgw /kjk fxjkbZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>bd cwan dk gh oSlk ,d nSR; gks;
                tkbZAA4AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;wa 'kaaHkw ds js oj ls] gks ,d ls
                vusdk]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>yM+rs gS jächt xbZ nsfo;ka FkdkbZAA5AA
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj vla[; jächtksa ds
                mRiUu gks yM+rs ns[k lc nsox.k Hk;Hkhr gksrs gSa vkSj lc nsfo;ka Fkd dj grk'k
                gks Jh vafcdkth ds ikl tkrh gS vkSj lc gky dg lqukrh gS rc vafcdkth Jh dkfydkth
                ls bl izdkj dgrh gS &amp; </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; esjs 'kadj n'kZu nhuks
                eq&gt;sA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>txnEc Hkus lqjjkbZ gS] dkyh ls dgr
                le&gt;kbZ gSAAVsjA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>nsg foLrkfjr djks v: eq¡g dks Qsyk
                ysoks] jDr dh cwanksa dks ukfg Hkwfe iS fxjus tks nks] ysoks yksgw dks isV
                ipkbZ gSAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>u jDr Hkwfe iS fxjs u nSR; tuesaxs
                dHkh] tks gS bUgsa ,d ,d djds ekj Mkjsaxs vHkh] ;gh tk; djksuh mikbZ gSAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj Jh vfEcdkth ds
                opu lqu Jh pkeqaMk jächt ds 'kjhj ls mRiUu :f/kj dk iku djrh gS vkSj Jh
                vafcdkth mls ewly ls dwV Mkyrh gSA pkeqaMk mldh nsg ds lc [k.M [kk Mkyrh gSA bl
                izdkj mlds jä ls ftrus mRiUu gq, d`f=e jächt Fks mu lcdk uk'kdj dkfydkth var
                esa jgs gq;s jächt dks Hkh [kk Mkyrh gSA fQj 'ks"k cps gq;s jk{'{'}kl Hkkxdj
                'kqaHk ds ikl tkrs gSa vkSj dgrs gSa &amp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ukFk dSls xt dks Qan
                NqM+k;kA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Hkx nkuo lsuk vkbZ] gky lc 'kqaHk dks
                nsr lqukbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jächt ;ks)k cyokuk] v: nkuo leqnkbZ]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>twat ejs j.k [ksr ijs gS] fdldh dNq uk
                pykbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>uk tkus gS dgk¡ ls vkbZ ukuk :i
                /kjkbZ]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>,d ugha vusd nsfo;ka] yM++us gsrq
                yM+kbZAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>egk fodjky djky dkfydk] pgq¡ fn'k eq¡g
                iljkbZ]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jächt dks jDr fi;ks gS] ih ih vkt
                v?kkbZAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gs egkjkt vkt ge rqedks lR; dgr
                leq&gt;kbZ]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>og ukgh dksbZ izkd`r ukjh] gS tuuh
                egkekbZAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>og ukjh yM+us ds ;ksxqq fu'p; dj gS
                ukbZ]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tku Hkyks viuks v: dqy dks] yhts tk;
                eukbZAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj nSR;ksa ds lR;
                opu lqu dky ls izsfjr vkSj ejus dh bPNk fd;s 'kqaHk] vks"V dkairk gqvk mu
                jk{'{'}klksa dks rqjar gh ikrky dks Hkkx tkus dk gqDe nsrk gS vkSj vius HkkbZ
                fu'kqaHk dks cqykdj lykg djrk gSA fu'kqaHk Hkqtk Bksd vfr Øks/k dj ml ekuuh;
                L=h dks eku ;qDr gh idM+ ykus dh izfrKk djrk gS vkSj ,d cM+h lsuk ys j.k {'{'}ks=
                dks iz;k.k djrk gSA mldks e; lsuk ds bl izdkj ejus ds fy, m|r ns[k Hkxorh Jh
                vafcdkth g¡l dj dkfydkth ls bl izdkj dgrh gSA </span></p>
            <p className="MsoNormal heading" align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&amp;% foJke%&amp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; dkfyaxM+kA </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; ykyq ykt gekjh j[kjsA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>g¡l cksyh vkn HkokuhAAVsjAA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ns[kks vc ;s yM+us dkj.k vk;s gS
                vfHkekuhAA1AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ns[kr ftuds oa'k [kik;ks] rks Hkh gesa
                u fiNkuhAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dNq ukagh fgr tkur viuks] dSls gS
                vKkuhAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;s gh gekjh ek;k tx esa] gS lc txr
                HkqykuhAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj Jh Hkxorhth {'}'}kjk
                Jh dkfydkth ds izfr dgq gq;s opu lqu dj fu'kqaHk Øks/k dj ck.k pykrk gSA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; esjk txk ds tkscu yqVkjhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>?ku?kksj ;q) epk;kjhAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vk; fu'kqaHk tqM+k paMh lax] [khap ds
                ck.k pyk;k jhAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>paMh fut lj lka/k rqjar gh] rkdks ekj
                fxjk;kjhAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ys fut lsuk vU; vlqjx.k] xjt xjt
                p&lt;+ vk;kjhAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>nkar dVkdV thHk yikyi] djrs nkuo
                /kk;kjhAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>flag xjt dj nSR; iNkjs] phj phj
                xVdk;kjhAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj nsoh vkSj nkuoksa
                esa ?kksj ;q) gksrk gSA nkuoksa dks ekjs tkrs ns[k fu'kqHk vfr jks"k;qä
                gks] nk:.k xnk ys nsoh ds fudV flag ds eLrd dks ekjrk gS vkSj fQj g¡l dj nsohth
                dks Hkh ekjrk gSA nsohth dqfir gks ekjrs gqos fu'kaqHk dks vkxs fLFkr ns[k
                [kM~x ls mldk eq.M vFkkZr~ eLrd ghu /kM+ vfr vk:.k lq:i xnk gkFk esa fy;s gq,
                Hkze.k dj nso x.kksa dks =kl fn[kkrk gSA rc Jh nsohth mlds gkFk iSjksa dks ck.k
                ls Nsnu dj Mkyrh gS vkSj og izk.k jfgr gks i`Foh ij ioZr dh rjg fxj iM+rk gSA
                nSR;ksa dh lsuk esa gkgkdkj ip tkrk gS vkSj cps gq, lSfud Hkkx dj jkt efUnj esa
                tk 'kqEHk ls dgrs gSa &amp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; lksguh </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; gsjh ;'kksnk rksls d:axh
                yM+kbZ] rsjs dkuu us eqjyh pqjkbZA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqugks lqugks lqugks u`ijkbZ] lej gky
                ge nsr lqukbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vfr nk:.k laxzke gqvks gk¡] thrs lqj
                lc nSR; gjkbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rkr fu'kqEHk egkcy /kkjh] vkSj ckadh
                lsuk lkjh] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>paMh us lc fn;s gS [kikbZAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>og ukagh lk/kkj.k ukjh dj ns[kk geus
                fuj/kkjh] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gS tx txuh og egkekbZAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ekuks ekuks ekuks Egkjh] NksM+ksa
                NksM+ksa NksM+ks jkjh] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Hkkx pyks blgh esa HkykbZAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj mu nSR;ksa ds opu
                lqu 'kqEHk Øks/k djrk gS vkSj bl izdkj opu cksyrk gS &amp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; izHkkrh </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; tkfx;s xksiky yky uUn ds
                nqykjsA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Øks/k Hkj cSu 'kqEHk cksys
                vdqykbZAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>'kwjohj lkFk lkjks] vkSj fiz; HkkbZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>esjs dkt vkt j.k [ksr jgs tkbZAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tks vcs esa Hkkxdj izk.k ywa cpkbZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dhjrh u gks; gks; yksd esa galkbZAA2AA
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dky dh xfr dks dkabZ lksp eu ekabZ]] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gksugkj gks; vugksuh gks; ukabZAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;g fopkj vkt ge djsaxs yM+kbZ] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ykoks tk; lsuk lc lkt dks ltkbZAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj ;q) dk n`&lt;+
                fu'p; djds 'kqEHk gkFkh] ?kksM+s] jFk vkSj iSny pkj izdkj dh lsuk ys ;q) LFky
                dks tkrk gS vkSj ogka rhu yksd dks eksfgr djus oky flag ij p&lt;+h gqbZ lc vkHkj.k
                vkSj Hkw"k.kksa ls ;qä Jh txnfEcdkth dks ns[krs gh 'kqHk eksfgr gks tkrk
                gS vkSj bl izdkj dgrk gS &amp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; izHkkrh </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; eSa ek[ku ugha [kk;ks esjh
                eS;k eSa ek[ku ugha [kk;ksjhA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dkekrqj gks; 'kqEHk Hkus yf[k] ewjrh
                vfr eugkjhAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rqe ukjh fr; /keZ fuHkkvks] NksM+ks
                ;q) fopkjh]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>HkkSag p&lt;+kdj lSu pykvks]
                /kuq"k ck.k nks MkjhAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gko Hkko dVk{'{'}k :i dks 'kLrj ysoks
                /kkjh]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>aaaaaaaaaaaamÙke tu dks y{'{'}; cukdj
                ?kk;y djks fugkjhAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>eSa ;ks)k gw¡ vfr cyokuk] rqe ;qorh
                lqdqekjh]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vcyk is eSa gkFk pykdj dSls d:a
                izgkjhAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;q) dju dh gh tks bPNk gS tks vkt
                rqekjh] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>djdl jkaM dks :i cukyks] rt ;s lc
                fl.kxkjhAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dNq bd Øks/k diV dj la;qä] opu u nsok
                mpkjh] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rks ge rqe lax ;q) djsaxs] ;g fu'p;
                fuj/kkjhAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; 'kqaHk dks dkekrqj tku Jh
                vfEckdkth bl izdkj dgrh gS &amp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; HkSjoh </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; dkgq fo/k feyrk fxj/kkjhA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>g¡l cksyh vkn HkokuhAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dkju :i dju lqj dkjt] eSa gw¡ ;wa
                izdVkuhAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;g esjk gh vkSj :i gS] dkyh dky
                fu'kkuhAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;kds lax esa ;q) djks rqe] gS ;g rqejs
                gh lekuhAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>nksgk </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vl dfg cksyh vafcdk dkyh ls le&gt;k;A</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tkvks rqe laxzke dj nsok 'kqaHk [kik;AA
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj dky :fi.kh dkfydk
                dky ls izsfjr fd;s tkus ij osx ls xnk mBk ds laxzke esa [kM+h gksrh gS vkSj
                'kqaHk vkSj dkfydk ds chp Hka;dj ;q) gksrk gSA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; HkSjoh</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dkyh ls 'kqEHk yjs gSAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lqjeqfu lcds ns[krs] xnk mBkdj ,SapA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dkyh dks dj Øks/k ds] ekjh 'kqEHk us
                [kSapAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>R;ksafg nsoh dkfydk] dj fut xnk izgkj]
            </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rksM+ Lo.kZ jFk 'kqEHk dks] lkjfFk
                Mkfj;ks ekjAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fcu jFk iSny gks; ds] dkyh ds f&lt;+ax
                tk;A </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>xnk mBk fqQj 'kqEHk us] ekfj Nkfr ds
                eka;AA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>cpk xnk ds ikr dks] dkyh Øks/k ds
                lkFkA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dkV fn;k fut [kM~x ls mldk cka;k
                gkFkAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>fcu jFk v:a ,d ckgq ds] bd ckgq ds
                tksj] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>:f/kj cgkrk 'kqEHk gS] ;q) djs
                ?ku?kksjAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vc dkyh us osx ls] tk; 'kqEHk f&lt;ax
                nkSjA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lfgr xnk nwth Hkqtk] Mkfj nkfguh
                vksjAA6AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Hkqtk dVr gh Øks/k dj] pY;ks dkyh dh
                vksjA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>^Bgj* ^Bgj* ;wa cksyrks] opu cM+ks cj
                tksjAA7AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vkrs gh fut ikl esa] dkyh us bl ckjA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>[kM~x ys &gt;V 'kqEHk dk] eLrd yhuk
                mrkjAA8AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>eLrd dVrs gh egk] nSR;u dk flj rktA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tk; ijk /kj.kh fxjk] izk.k fudy x;s
                HkktAA9AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>bl fo/k fut ifr 'kqEHk dks] ej;ks tku
                rRdkyA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>cps gq;s tks nSR; Fks] Hkkx pys
                ikrkyAA10AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; HkSjoh </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; tkxks tkxks gS erokjh jkt
                nqykjhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tS tS tS 'kCn mpkjh lqjxu eqfu lc gks;
                lq[kkjhA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>nq%[k foyk; x;s lc Hkkjh v: vkuUn eu
                eksn vikjhAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lc nkuo ny [kkst [kik; s] lqjxu fQj
                fut fut in ik;ks] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>v: gjf"kr gks eaxy xk;s] &gt;j
                &gt;j &gt;j Qqyu &gt;j Mkjh] tS tS tSAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj lc nSR;ksa ds
                uk'k gks tkus ij bUækfn nso izlUu gksrs gSa vkSj Hkxorh Jh egkek;k ds ikl vkdj
                mudh Lrqfr djrs gSaA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx &amp; HkSjoh</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; tS tS tS dUgS;kyky dh jsA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tS tS vafcds vafcds vafcds js]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tS tS vafcds vafcds vafcds jsAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka gka lkju dkt lqju ds js]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vkbZ jaHkk :i cuk; dsjs] tS tSAA1AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka gka jDrcht dks [kik; ds js] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ih;ks ih;ks gS jä v?kk; dsjs] tS
                tSAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka gka paM v: eqaM foMkj ds js] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ekjs 'kqaHk fu'kqaHk iNkj dsjs] tS
                tSAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka gka fo'o pjkpj /kkj dsjs] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dj ikyu ysr lagkj dsjs] tS tSAA4AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka gka usfr usfr c[kku dsjs] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lc osn Fkds xq.k xk; dsjs] tS tSAA5AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka gka nso lHkh dj tksj dsjs] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;gh oj ekaxs flj uk;a dsjs] tS tSAA6AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>gka gka Hko Hko fut HkDru dsjs] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dhtks fl) dkt ;wa vk; dsjs] tS tSAA3AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; bl izdkj nsorkvksa dh
                Lrqfr ls izlUu gks Jh nsohth mudks vHk; ojnku nsrh gS rFkk mDr pfj= dk egkrE;
                bl izdkj o.kZu djrh gS &amp; </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jkx HkSjoh </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>vc lqfu;s pfj= egkRe] gsjh vc lqfu;s
                pfj= egkReAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>AAnksgkAA &nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>esjs mÙke pfj= dks] lqus djs xq.kxku] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rkdks Qy egkRe dgw¡] lquksa nso ns
                dkuAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>pksj] jkt] gfFk;kj vkS] vfXu 'k=q
                egku] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>budk Hk; O;kis ugha] tks xq.k djs
                c[kkuAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>lq[k lEifr larfr c&lt;+s] feVs rhu
                larki] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tUe tUe ds iqfu dVs] dksfV izcy tks
                ikiAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>iq= tUe iwtk rFkk] C;ko ;K cfynku] </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>,srs eaxy dk;Z esa] vo'; djks
                xq.kxkuAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>;k ?kj esa fur gksr gS] p.Mh dk
                xq.kxku]</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ogka ij eSa fu'k fnu jgw¡]] dju gsrq
                dY;k.kAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>tckuh &amp; rn~i'pkr Jh nsohth viuh lc
                foHkwfr;ksa dks ys var/;kZu gks tkrh gS vkSj nsork yksx vius LFkkuksa ij pys
                tkrs gSaA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>c/kkbZ </span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; jaxhyh vkt dh ?kfj;kaA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>jaxhyh vkt dh ?kfj;kaAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ctr gS bUæ ds ckts] yxh jax izse dh
                &gt;fj;kaAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>Nfo njckj dh uhdh] djr gS ukp
                lqjifj;kaAA2AA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>pys lc pky fBefBejh] djs &gt;adkj
                uwifj;kaAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dgw¡ D;k ckr tyls dh] tkrcfy ^jke*
                fdadfj;kaAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dFkk egkrE;</span></p>
            <p className="MsoNormal subHeading" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>rtZ &amp; dFkk jks jl fcanjkou lwa
                vk;ksA</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dFkk jks jl lq/kk fla/kq lwa vk;ks]
                gka js ekus Jh xq:ukFk fiyk;ks] dFkk jks j.k ef.k js {'}'}hi lwa vk;ksAAVsjAA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ldy iqjkuu jks chp cM+ks bd] gk¡ ajs
                vks rks nsoh iqjk.k dgk;ksAA1AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>b.kjs i&lt;+u lq.ku jks egkRe] gk¡ ajs
                vks rks osn pkj ;'k xk;ksAA2AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>ft.k ;g dFkk lq.kh vkS lq.kkbZ] gk¡
                ajs oks rks lc rhjFk Qy ik;ksAA3AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>olqnso vkSj nsodh lquh tc] gk¡ ajs os
                rks d`".k lks yky f[kyk;ksAA4AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>bd fpÙk gks; lq.kks lc HkkbZ] gk¡ ajs
                fl) dkjt gks eu pk;ksAA5AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>^jke* dgs xq:nso d`ik dj] gk¡ ajs
                Egkus 'kqHk volj cxlk;ksAA6AA </span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal heading" align="center" style={{ marginBottom: '0in', textAlign: 'center', lineHeight: 'normal' }}><b><u><span lang="EN-IN" style={{ fontSize: `${fontSize + 4}.0pt`, fontFamily: '"Kruti Dev 010"' }}>dFkk lekIr</span></u></b></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            <p className="MsoNormal" style={{ marginBottom: '0in', textAlign: 'justify', textJustify: 'inter-ideograph', lineHeight: 'normal' }}><span lang="EN-IN" style={{ fontSize: `${fontSize}.0pt`, fontFamily: '"Kruti Dev 010"' }}>&nbsp;</span></p>
            
        </div>


    );
}

export default Ambika;